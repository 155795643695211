<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
/deep/ .ant-card-body {
  padding: 24px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  > .ant-row-flex {
    > div {
      min-height: 40px !important;
      line-height: 40px;
    }
  }
}
</style>
<style lang="less" scoped>
.input-left {
  width: 90px !important;
}
.page-header-index-wide {
  padding-bottom: 50px;
}
.ant-row-flex {
  /deep/ .ant-card {
    height: 100% !important;
    .ant-col-xs-24{
    .ant-form-item-label,
     >span {
      display: inline-block;
      text-align: left;
      width: 90px;
      color: #000;
      font-size: 16px;
    }
    }
  }
}
.form {
  /deep/ .ant-row-flex {
    margin-top: 0 !important;
  }
}
.ant-card {
  box-shadow: 0 0 5px 0 #ccc;
}
.ant-card-body {
  > .ant-row-flex {
    margin-top: 0 !important;
  }
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
.table-operator{
  >button{
    margin-right: 10px;
  }
}
.remark{
    >div{
    display: flex;
    font-size: 12px;
    >div {
    width: 64%;
    >span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    }
     span{
      font-size: 14px;
     }
    }
}
.Beyond-the-hidden{
  display: flex;
  b{
   font-weight: 400;
   white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
  <div class="page-header-index-wide">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <div class="table-operator">
        <span>订单号：{{ orderInfo.orderSn }}</span>
        &nbsp;&nbsp;&nbsp;
        <span>订单类型:  {{ orderInfo.orderTypeVal }}</span>
        &nbsp;&nbsp;&nbsp;                
        <span v-if="orderInfo.orderType==1 && orderInfo.paymentWay != 1 && orderInfo.paymentState==0">订单状态:   <font color="red">待确认</font></span>
        <span v-if="!(orderInfo.orderType==1 && orderInfo.paymentWay != 1 && orderInfo.paymentState==0)">订单状态:  <font color="red">{{ orderInfo.paymentStateVal }}</font></span>
      </div>
      <div class="table-operator" style="margin-top: 20px">
        <a-button v-show="orderTakingBool" type="primary" @click="takingOrderConfirm()" v-action:takingOrder>接单</a-button>
        
        <a-button type="primary" v-show="orderCancelBool" @click="cancel()" v-action:cancelOrder>{{ cancelTitle }}</a-button>
        
        <a-button type="primary" v-show="orderDiffBool || orderTakingBool" @click="diffPrice(orderInfo)" v-action:diffPriceOrder>补差价</a-button>
        
        <a-button type="primary" v-show="saveGoodsBool || orderTakingBool" @click="saveGoods()" v-action:modifyGoods>保存商品信息</a-button>
        
        <a-button v-show="inWarehouseBool" type="primary" @click="inWarehouseConfirm()" v-action:but_less_load_inwarehouse>入库</a-button>
        
        <a-button v-show="secondaryOrderInitiateBool" type="primary" @click="secondaryOrderConfirmSubmit()" v-action:launchSecConfirm>订单信息有误发起二次确认</a-button>

        <a-button v-show="secondaryOrderConfirmingBool" type="primary" @click="secondaryOrderConfirmingSubmit()" v-action:secondaryOrderConfirming>已二次确认可入库</a-button>
        
        <a-button v-show="disDriverBool" type="primary" @click="loadDispatch()" v-action:but_less_load_dispatch>分配司机</a-button>

        <a-button v-show="quoteDetailBool"  type="primary" @click="quoteDetail()" v-action:quoteDetail>报价明细</a-button>
      </div>

      <a-row v-show="lingDanShow || zhengCheOneShow" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="寄方" :bordered="false">
            <send-form ref="send" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="收方" :bordered="false">
            <recipient-form ref="recipient" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-row v-show="zhengCheMultiCarrayShow" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="寄方" :bordered="false">
            <send-form ref="multiSend" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-row v-show="zhengCheSingleCarrayShow" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="收方" :bordered="false">
            <recipient-form ref="singleRecipient" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <whole-car-order-form ref="singleOrder" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-row v-show="lingDanShow" :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
            <goods-form ref="goods" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <order-form ref="order" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-row
        v-show="zhengCheOneShow || zhengCheMultiCarrayShow"
        :gutter="24"
        type="flex"
        :style="{ marginTop: '24px' }"
      >
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
            <whole-car-goods-form ref="wholeGoods" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <whole-car-order-form ref="wholeCarOrder" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <a-row
        v-show="zhengCheMultiCarrayShow"
        v-for="(item, index) in multiCarray"
        :key="index"
        :gutter="24"
        type="flex"
        :style="{ marginTop: '24px' }"
      >
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="收方" :style="{ height: '100%' }">
            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span> 收件人: </span>{{ item.realname }} </a-col>
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span>联系方式: </span> {{ item.phoneNumber }} </a-col>
            </a-row>

            <a-row :gutter="24" type="flex" style="margin-top: 20px" class="remark">
              <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
               <span>收件人地址:</span>  
               <div> <a-tooltip placement="bottomLeft"><template slot="title">{{ item.geoAddressDetail }}</template> {{ item.geoAddressDetail }}</a-tooltip></div>               
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span>物品名称: </span> {{ item.goodsInfo.goodsName }} </a-col>
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden">
               <span>到货时间:</span> <b>{{ item.goodsInfo.expectDispatchDate }}</b>
              </a-col>
            </a-row>
            <a-row :gutter="24" type="flex" style="margin-top: 20px" class="remark">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span>总件数:</span>  {{ item.goodsInfo.goodsNum }} </a-col>
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span>备注: </span>
                <div> <a-tooltip placement="bottomLeft"><template slot="title"> {{item.goodsInfo.goodsRemark }}</template> {{ item.goodsInfo.goodsRemark }}</a-tooltip></div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-row
        v-show="zhengCheSingleCarrayShow"
        v-for="(item, index) in singleCarray"        
        :key="index"
        :gutter="24"
        type="flex"
        :style="{ marginTop: '24px' }"
      >
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="寄方" :style="{ height: '100%' }">
            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span> 寄件人:</span> {{ item.realname }}</a-col>
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                <span>联系方式:</span> {{ item.phoneNumber }}
              </a-col>
            </a-row>

            <a-row :gutter="24" type="flex" style="margin-top: 20px" class="remark">
              <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                <span> 寄件人地址:</span>
                <div> <a-tooltip placement="bottomLeft"><template slot="title">{{ item.geoAddressDetail }}</template> {{ item.geoAddressDetail }}</a-tooltip></div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                <span>物品名称:</span> {{ item.goodsInfo.goodsName }}
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                <span>总件数:</span> {{ item.goodsInfo.goodsNum }}
              </a-col>
            </a-row>
            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                <span>总重量:</span> {{ item.goodsInfo.weight }} KG
              </a-col>

              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"
                ><span>总体积: </span> {{ item.goodsInfo.volume }} M³
              </a-col>
            </a-row>

            <a-row :gutter="24" type="flex" style="margin-top: 20px">
              <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden">
                <span> 发货时间:</span> <b>{{ item.goodsInfo.expectPickupDate }}</b>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col
          v-show="(lingDanShow || zhengCheShow) && orderInfo.orderCurrentStep >=2"
          :xl="12"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <a-card :bordered="false" title="调度" :style="{ height: '100%' }">
            <storage-form ref="storage" :showSubmit="false" />
          </a-card>
        </a-col>
        <a-col v-show="zhengCheShow" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="司机端" :style="{ height: '100%' }">
            <driver-form ref="driver" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col
          v-show="secondaryOrderInfoBool"
          :xl="12"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <a-card :bordered="false" title="二次确认记录" :style="{ height: '100%' }">
            <order-confirm-record-form ref="orderConfirmRecord" :showSubmit="false" />
          </a-card>
        </a-col>

      </a-row>
    
    </div>

    <a-modal
      title="接单"
      :width="200"
      :visible="takeOrderVisible"    
      @cancel="takeOrderCancel"
    >
      <a-row :gutter="24">
          <a-col :span="24">            
              是否接单？
          </a-col>
          
        </a-row>   
    <template slot="footer">
      <a-button key="cancel"  @click="takeOrderCancel"  style="margin-right:10px">否</a-button>
      <a-button  key="forward"  type="primary" @click="takingOrder">是</a-button>      
    </template>
  </a-modal>

  <a-modal
      title="入库"
      :width="200"
      :visible="inWarehouseVisible"    
      @cancel="inWarehouseCancel"
    >
      <a-row :gutter="24">
          <a-col :span="24">            
              是否入库？
          </a-col>
          
        </a-row>   
    <template slot="footer">
      <a-button key="cancel"  @click="inWarehouseCancel"  style="margin-right:10px">否</a-button>
      <a-button  key="forward"  type="primary" @click="inWarehouse">是</a-button>      
    </template>
  </a-modal>



     <cancel ref="cancelModal" @ok="handleOk"/>
     <quoteDetail ref="quoteDetailModal" @ok="handleOk"/>
     <secondaryOrderConfirm ref="secondaryOrderConfirmModal" @ok="handleOk"/>
     <orderConfirm ref="orderConfirmModal" @ok="handleOk"/>
     <diffPrice ref="diffPriceModal" @ok="handleOk" />
    <LoadDispatchDialog ref="loadDispatchDialog" @ok="handleOk"></LoadDispatchDialog>
    <TltDispatchDialog ref="tltDispatchDialog" @ok="handleOk"></TltDispatchDialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mixinDevice } from '@/utils/mixin'
import Cancel from './modules/Cancel'
import QuoteDetail from './modules/QuoteDetail'
import SecondaryOrderConfirm from './modules/SecondaryOrderConfirm'
import OrderConfirm from './modules/OrderConfirm'
import { enumerationData  } from '@/api/common'

import DiffPrice from './modules/DiffPrice'
import SendForm from './OrderDetail/SendForm'
import RecipientForm from './OrderDetail/RecipientForm'
import GoodsForm from './OrderDetail/GoodsForm'
import WholeCarGoodsForm from './OrderDetail/WholeCarGoodsForm'
import WholeCarOrderForm from './OrderDetail/WholeCarOrderForm'
import OrderConfirmRecordForm from './OrderDetail/OrderConfirmRecordForm'
import DriverForm from './OrderDetail/DriverForm'
import OrderForm from './OrderDetail/OrderForm'
import StorageForm from './OrderDetail/StorageForm'
import { getOrderDetail, orderTaking, saveGoods, orderCartfulDetail, getLoadDispacth,getTltDispacth} from '@/api/order'
import LoadDispatchDialog from '@/views/serve/LoadDispatchDialog'
import { updateInWarehouseStatus } from '@/api/manage'
import TltDispatchDialog from "@/views/serve/TltDispatchDialog";

export default {
  name: 'OrderDetail',
  mixins: [mixinDevice],
  components: {
    TltDispatchDialog,
    LoadDispatchDialog,
    SendForm,
    RecipientForm,
    GoodsForm,
    DriverForm,
    OrderForm,
    Cancel,
    QuoteDetail,
    DiffPrice,
    OrderConfirmRecordForm,
    SecondaryOrderConfirm,
    OrderConfirm,
    WholeCarGoodsForm,
    WholeCarOrderForm,
    StorageForm,
  },

  data() {
    return {
      orderId: '',
      orderSn: '',
      orderInfo: {},
      goodsInfo: {},
      fromroute: '',
      orderTakingBool: false, //接单
      orderCancelBool: false, //订单取消
      orderDiffBool: false, //补差价
      saveGoodsBool: false, //保存商品      
      inWarehouseBool: false, //入库            
      disDriverBool: false, //分配司机      
      secondaryOrderInitiateBool:false,//二次订单确认
      secondaryOrderConfirmingBool:false,//二次订单确认中
      secondaryOrderInfoBool:false,//二次确认订单信息
      quoteDetailBool:false,//报价明细

      takeOrderVisible:false,//是否接单
      
      inWarehouseVisible:false,//是否入库
      lingDanShow: false, //零单
      zhengCheShow: false, //整车
      zhengCheOneShow: false, //一提一送
      zhengCheMultiCarrayShow: false, //一提多送展示
      zhengCheSingleCarrayShow: false, //多提一送展示
      multiCarray: [], //一提多送地址商品信息
      singleCarray: [], //多提一送地址商品信息
      visible_dispatch: false, //显示调度弹窗
      visible_dispatch_tlt:false,
      orgOrderId: {}, //当前页的企业订单Id
      cancelTitle:"",
    }
  },
  watch: {
    $route: function (to, from) {            
      if (to.fullPath != from.fullPath) {
        this.initData()
      }
    },
  },
  activated() {
  
    this.initData()
  },
  methods: {
    initData() {     
      //功能按钮是否展示
      this.orderTakingBool = false
      this.orderCancelBool = false
      this.orderDiffBool = false
      this.saveGoodsBool = false
      this.inWarehouseBool = false
      this.disDriverBool = false
      this.secondaryOrderInitiateBool = false
      this.secondaryOrderConfirmingBool = false
      this.secondaryOrderInfoBool = false
      //是否展示模块
      this.lingDanShow = false
      this.zhengCheShow = false
      this.zhengCheOneShow = false
      this.zhengCheMultiCarrayShow = false
      this.zhengCheSingleCarrayShow = false

      this.orderId = this.$route.query.orderId
      this.orderSn = this.$route.query.orderSn
      this.fromroute = this.$route.query.fromroute

      if (this.orderId == undefined && this.orderSn == undefined) {
        return false
      }
      this.loadData(this.orderId)
    },
   
    loadData(orderId) {

       var enumerationParam = {tableName:'sysCOrder'}
       enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){                      

              this.$refs.goods.goodsLoad(res.result)
            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
        })  


      var param = { id: orderId, orderSn: this.orderSn }
      let that = this
      getOrderDetail(param).then((res) => {

        if(res.code != 0){
           this.$notification.error({
              message: '加载失败',
              description: res.message,
            })
           return false
        }
        this.orderInfo = res.result.orderInfo
        this.goodsInfo = res.result.goodsInfo

         //二次确认订单信息
        if(that.orderInfo.orderType == 1 && res.result.secondaryConfirmationList.length > 0 ){
          this.secondaryOrderInfoBool = true
        }

        //操作按钮权限
        if (this.orderInfo.orderType == 1 &&  this.orderInfo.orderState == 1 && this.orderInfo.paymentState == 2 && this.orderInfo.cancelAuditState !=1) {

          this.orderTakingBool = true
        }
        
        if (this.orderInfo.orderType == 1 && (this.orderInfo.orderState != 3 && this.orderInfo.orderState != 6) && this.orderInfo.orderCurrentStep < 3) {

          this.orderCancelBool = true
        }

                  
        if (
          that.orderInfo.orderType == 1 &&
          that.orderInfo.orderCurrentStep == 2 &&
          that.orderInfo.pickupState == 2 &&
          that.orderInfo.paymentState == 2 &&
          (that.orderInfo.orderState == 2 || that.orderInfo.orderState == 6) &&
          that.fromroute == 'InStorage'
        ) {

          that.inWarehouseBool = true
          if(that.orderInfo.orderState == 2){
              that.secondaryOrderInitiateBool = true
          }          
        }

        if(that.orderInfo.orderType == 1 && that.orderInfo.orderCurrentStep == 2 &&   that.orderInfo.pickupState == 2 && that.orderInfo.paymentState==2 && that.orderInfo.orderState == 5){
             
              that.secondaryOrderConfirmingBool = true                                         
              that.saveGoodsBool = true   
              that.orderDiffBool = true              
        }

        if (
          that.orderInfo.orderCurrentStep == 2 &&
          that.orderInfo.paymentState == 2 &&
          that.orderInfo.pickupState == 1 &&
          that.orderInfo.orderState == 2 &&
          (that.fromroute == 'LoadDispatch' || that.fromroute == 'TltTransport')
        ) {
          if(that.orderInfo.orderType == 2 && that.orderInfo.transportState==0){//整车未运输
            that.disDriverBool = true
          }
          if(that.orderInfo.orderType == 1 && that.orderInfo.pickupType==1){//零担需要提货
              that.disDriverBool = true
          }          
        }
       

        if (that.orderInfo.orderType == 1) {
           var fullPath = this.$route.fullPath
           var pos = fullPath.indexOf('order_detail_dis')      
           res.result.orderInfo.orderShow = pos == -1 ? true : false;
          if(res.result.orderInfo.orderShow){
            this.quoteDetailBool = true
          }
                      
          this.cancelTitle = that.orderInfo.orderSource==1 ? "申请取消订单" : "取消订单"
          res.result.orderInfo.orderDiffBool = this.orderDiffBool
          res.result.orderInfo.orderTakingBool = this.orderTakingBool

          //零单逻辑
          this.lingDanShow = true
          this.$refs.send.load(res.result.orderInfo)
          this.$refs.recipient.load(res.result.orderInfo)
          this.$refs.goods.load(res.result)
          this.$refs.order.load(res.result)
          if(res.result.secondaryConfirmationList.length > 0){
            this.$refs.orderConfirmRecord.load(res.result.secondaryConfirmationList[0])
          }          
        } else if (that.orderInfo.orderType == 2) {
          //整车逻辑
          this.zhengCheShow = true
          var cartfulParam = { id: this.orderInfo.id }
          orderCartfulDetail(cartfulParam).then((res) => {

            if(res.code != 0){
              this.$notification.error({
                  message: '加载失败',
                  description: res.message,
              })
              return false
            }

            this.orderInfo.vehicleTypeVal = res.result.vehicleTypeVal
            if (that.orderInfo.pickupWay == 1 && that.orderInfo.dispatchWay == 1) {
              this.zhengCheOneShow = true
              this.$refs.send.load(this.orderInfo)
              this.$refs.recipient.load(this.orderInfo)              
              this.$refs.wholeGoods.load(res.result.shipperList[0].goodsInfo)
              res.result.shipperList[0].goodsInfo.certificateInfo = res.result.certificateInfo
              this.$refs.wholeCarOrder.load(this.orderInfo, res.result.shipperList[0].goodsInfo)
            }

            if (that.orderInfo.pickupWay == 1 && that.orderInfo.dispatchWay == 2) {
              this.zhengCheMultiCarrayShow = true
              this.$refs.multiSend.load(this.orderInfo)              
              this.$refs.wholeGoods.load(res.result.shipperList[0].goodsInfo)
              res.result.shipperList[0].goodsInfo.certificateInfo = res.result.certificateInfo
              this.$refs.wholeCarOrder.load(this.orderInfo, res.result.shipperList[0].goodsInfo)
              this.multiCarray = res.result.receiverList
            }

            if (that.orderInfo.pickupWay == 2 && that.orderInfo.dispatchWay == 1) {
              this.zhengCheSingleCarrayShow = true
              this.$refs.singleRecipient.load(this.orderInfo)
              res.result.shipperList[0].goodsInfo.certificateInfo = res.result.certificateInfo
              this.$refs.singleOrder.load(this.orderInfo, res.result.shipperList[0].goodsInfo)
              this.singleCarray = res.result.shipperList
            }
          })

          this.$refs.driver.load(res.result.pickUpPicList, res.result.arrivalPicList, res.result.orderExceptionList)
        }
        if (that.orderInfo.orderCurrentStep >= 2) {
          var dispatchParam = { orderSn: this.orderInfo.orderSn }
          if(that.orderInfo.orderType == 1){
              getLoadDispacth(dispatchParam).then((res) => {
                if (res.code == 0) {
                  var dispatchData = res.result
                  dispatchData.orderType = this.orderInfo.orderType
                  dispatchData.dispatchTypeVal = this.orderInfo.dispatchTypeVal
                  dispatchData.expectPickupDate = this.orderInfo.expectPickupDate
                  this.$refs.storage.load(dispatchData)
                }else{
                    this.$notification.error({
                        message: '获取调度信息',
                        description: res.message,
                      })
                    return false
                }
              })
          }else{
              getTltDispacth(dispatchParam).then((res) => {
                console.log('tltDispacth',res)
                if (res.code == 0) {
                  var dispatchData = res.result
                  dispatchData.orderType = this.orderInfo.orderType                
                  dispatchData.expectPickupDate = this.orderInfo.expectPickupDate
                  this.$refs.storage.load(dispatchData)
                }else{
                   this.$notification.error({
                        message: '获取调度信息',
                        description: res.message,
                      })
                   return false
                }
              })
          }
          

        }
      })
    },
    saveGoods() {
      const {
        $refs: { goods },
        $notification,
      } = this
      const goodsForm = new Promise((resolve, reject) => {
        goods.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          delete values.totalVolume
          resolve(values)
        })
      })

      this.errors = []
      const params = {}
      Promise.all([goodsForm]).then((list_values) => {
        var orderParam = list_values[0]
        orderParam.orderId = this.orderInfo.id
        orderParam.id = this.goodsInfo.id
        orderParam.requestType = 2
        return saveGoods(orderParam).then((res) => {
          if (res.code == 0) {
             this.initData()
             this.$notification.success({
              message: '修改商品信息',
              description: "操作成功",
            })
          } else {
            this.$notification.error({
              message: '修改商品信息',
              description: res.message,
            })
          }
          return res.result
        })
      })
    },
    cancel() {
      if (this.orderInfo.cancelAuditState == 1) {
        this.$notification.error({
          message: '提交审核',
          description: '订单处于待审核状态',
        })
        return false
      }
      this.$refs.cancelModal.cancel(this.orderInfo)
    },
    secondaryOrderConfirmSubmit(){
         this.$refs.secondaryOrderConfirmModal.secondaryOrderConfirm(this.orderInfo)
    },
    secondaryOrderConfirmingSubmit(){
      this.$refs.orderConfirmModal.orderSecConfirm(this.orderInfo)
      
    },
    quoteDetail(){
      console.log('orderId',this.orderInfo.id)
         this.$refs.quoteDetailModal.quoteDetail(this.orderInfo.id)
    },

    takingOrderConfirm(record) {

      this.takeOrderVisible = true     
    },
    takingOrder(){
      let _this = this
      var param = { orderId: _this.orderInfo.id }
      return orderTaking(param).then((res) => {
        this.takeOrderVisible = false 
        if (res.code == 0) {
          _this.initData()
          _this.$notification.success({
            message: '接单',
            description: `您已成功接单，请安排提货`,
          })
        } else {
          _this.$notification.error({
            message: '接单',
            description: res.message,
          })
        }
      })
    },
    takeOrderCancel(){
      this.takeOrderVisible = false
    },

    diffPrice(orderInfo) {
      this.$refs.diffPriceModal.diffPrice(orderInfo)
    },

    handleOk() {
      this.initData()
    },
    inWarehouseCancel(){
        this.inWarehouseVisible = false
    },
    inWarehouseConfirm(){
      this.inWarehouseVisible = true
    },
    inWarehouse() {      
      let values = {}
      values.orderIds = []
      values.orderIds.push(this.orgOrderId)
      values.type = 1
      values.status = 2
      updateInWarehouseStatus(values).then((res) => {
        this.inWarehouseVisible = false
        if (res.code == 0) {          
          this.initData()
          this.$notification.success({
            message: '入库',
            description: `操作成功`,
          })
        } else {
          this.$notification.error({
            message: '入库',
            description: res.message,
          })
        }
      })
    },
    loadDispatch() {
      //打开分配司机弹窗
      //this.visible_dispatch = true
      //区分整车调度和零担提货调度
      if(this.orderInfo.orderType==1){
        this.visible_dispatch = true
      }else{
        this.visible_dispatch_tlt = true
      }

    },
  },

  mounted() {
    this.orgOrderId = this.$route.query.orgOrderId
  },
  beforeRouteLeave(to, from, next) {
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true)
    next()
  },
}
</script>

<style lang="less">
/deep/ .ant-form-item-label{
  text-align: left !important;
}
</style>