<style lang="less" scoped >
/deep/ .ant-form-item-label {
  text-align: left !important;
  width: 100px;
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>
<template>
  <a-modal
    key="dispatch"
    title="调度"
    :width="500"
    :visible="$parent.visible_dispatch"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    :closable="true"
    @cancel="dispatchHandleCancel"
    :centered="true"
    :mask="true"
    :footer="null"
    :maskClosable="false"
    :body-style="{ height: '530px' }"
  >
    <div>
      <a-form @submit="dispatchHandleSubmit" :form="form">
        <a-form-item
          label="选择司机"
          :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-select placeholder="请选择" @change="handleChange2" v-decorator="['driverId']">
            <a-select-option v-for="(item, index) in driverList" :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="司机姓名"
          :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]"
            :read-only="!newDriver"
            name="name"
          />
        </a-form-item>

        <a-form-item
          label="司机电话"
          :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['mobile', { rules: [{ required: true, message: '请输入名称' }] }]"
            :read-only="!newDriver"
            name="mobile"
          />
        </a-form-item>

        <a-form-item
          label="预约时间"
          :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-date-picker
            :show-time="{ format: 'HH' }"
            format="YYYY-MM-DD HH:00:00"
            v-decorator="['planStartDate', { rules: [{ required: true, message: '请输入名称' }] }]"
            name="planStartDate"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item
          label="仓库名称"
          :labelCol="{ lg: { span: 5 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-select placeholder="请选择仓库"
                    v-decorator="['warehouseId',{rules: [{ required: true, message: '请选择仓库' }]}]"
          >
            <a-select-option v-for="(item, index) in warehouseList" :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="选择车辆"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
          <a-select
            placeholder="请选择"
            @change="handleChange4"
            v-decorator="[ 'vehicleId',
               {rules: [{ required: false, message: '请选择车辆' }]}
              ]"
          >
            <a-select-option v-for="(item, index) in vehicleList" :key="index" :value="item.id">
              {{ item.vno }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="车牌号"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
          <a-input
            v-decorator="[
            'vno',
            {rules: [{ required: false, message: '车牌号' }]}
          ]"
            :readonly="!newVehicle"
            name="vno"/>
        </a-form-item>

        <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
          <a-button style="margin-right: 8px" @click="dispatchHandleCancel">取消</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment'
import { refundPriceOperate } from '@/api/order'
import {findListDriver, findListVehicle, findListWarehouse, loadDispacth} from '@/api/manage'

export default {
  name: 'loadDispatchDialog',
  data() {
    return {
      form: this.$form.createForm(this),
      confirmLoading: false,
      driverList:[],
      driverInfoList:[],
      newDriver: true, //新司机，只有当选择手动录入司机是司机姓名电话可编辑
      newVehicle:false,//新车辆
      warehouseList:[],
      warehouseInfoList:[],
      vehicleList:[],
      addressoptions: [], //省市区选择数据
      warehousereRead:true
    }
  },

  methods: {
    dispatchHandleSubmit: function (e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = []
          values.planStartDate = moment(values.planStartDate).format('YYYY-MM-DD HH:mm:ss')
          values.orderIds.push(this.$parent.orgOrderId - 0)
          values.type = 1
          values.driver = values.name
          values.driverTel = values.mobile
          console.log('Received values of form: ', values)
          loadDispacth(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('更新成功，如需要请在列表页点击查询按钮，刷新数据')
                that.$parent.visible_dispatch = false
                //关闭路由
                that.$multiTab.closeCurrentPage()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    dispatchHandleCancel: function () {
      this.$parent.visible_dispatch = false
    },
    handleChange2(value) {
      if (value == 0) {
        this.newDriver = true
      } else {
        this.newDriver = false
        let selectedDriver = {}
        for (let i in this.driverInfoList) {
          if (this.driverInfoList[i].id == value) {
            selectedDriver = this.driverInfoList[i]
            break
          }
        }

        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile: selectedDriver.mobile,
        })
      }
    },
    handleChange4(value){
      console.log(value)
      if(value==0){
        this.newVehicle = true;
        this.form.setFieldsValue({
          vno:""
        })
      }else {
        this.newVehicle = false;
        var vehinfo = null
        for(var i in this.vehicleList){
          if(this.vehicleList[i].id==value){
            vehinfo = this.vehicleList[i]
            break
          }
        }
        this.form.setFieldsValue({
          vno:vehinfo.vno
        })
      }
    }
  },
  mounted() {},
  created() {
    let that = this
    this.tabObj = {}
    //初始化司机列表和仓库列表
    this.driverList = []
    this.driverList.push({ id: 0, name: '手动录入' })
    findListDriver({ status: 1 }).then((res) => {
      for (let i in res.result) {
        that.driverList.push({ id: res.result[i].id, name: res.result[i].name + res.result[i].mobile })
      }
      that.driverInfoList = res.result
    })
    this.warehouseList = []
    findListWarehouse({ status: 1 }).then((res) => {
      for (let i in res.result) {
        that.warehouseList.push({ id: res.result[i].id, name: res.result[i].name })
      }
    })
    //查询车辆列表
    this.vehicleList =[]
    findListVehicle({status:1})
      .then(res => {
        that.vehicleList.push({id:0,vno:"手动录入"})
        for(let i in res.result){
          that.vehicleList.push({id:res.result[i].id,vno:(res.result[i].vno)})
        }
      })
  },
}
</script>