<style lang="less" scoped>
/deep/ #depositReceiptUrl,
#pratiqueUrl,
#nucleinUrl {
  width: 128px;
  height: 128px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-form-item1 {
  min-height: 0 !important;
  width: auto !important;
}
/deep/ .ant-form-item {
  margin-right: 0 !important;
}
.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-row-flex {
    width: 100% !important;
    min-height: 45px;
    line-height: 45px;
    margin-right: 0 !important;
    /deep/ .ant-radio-wrapper {
      margin-right: 0 !important;
    }
    >div >span{
      display: inline-block;
      width: 100px;
      color: #000;
      font-size: 16px;
    }
  }
  .orderPayment{
    margin-left: 0 !important;
    display: flex;
    > div{
      min-height: 45px;
      line-height: 45px;
      display: flex;
      padding: 0 !important;
      >span{
        display: inline-block;
        width: 75px !important;
        font-size: 14px !important;
      }
    }
  }
}
.multi-form {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
}
/deep/ .ant-form-item-control-wrapper {
  width: 65%;
}
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}
/deep/ .ant-radio-wrapper-checked {
  > span:nth-child(2) {
    padding-left: 0 !important;
  }
}
.order-footer-link{
  width: 100%;
  margin: 30px 30px 20px 0;
  box-sizing: border-box;
  a{
    margin-right: 10px;
  }
}
.ant-modal-body{
  .ant-row-flex{
     >span:nth-child(1){
      display: inline-block;
      width: 90px;
      color: #000;
      font-size: 16px;
    }
  }
}
.ant-row-flex >div{
  padding-right: 0 !important;
}
.billing{
  display: flex;
  flex-wrap: wrap;
  >div{
    height: 45px !important;
  }
}
/deep/ .ant-card-head{
  padding: 0 !important;
}
/deep/ .ant-card-body {
    padding: 0 24px !important;
}
.remark{
    >div{
    display: flex;
    font-size: 12px;
    >div {
    width: 64%;
    >span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    }
     span{
      font-size: 14px;
     }
    }
}
.Beyond-the-hidden{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
 >div,
  b{
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
  <div layout="inline" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden" style="display: flex;"><span> 订单号: </span>
      <div> <a-tooltip><template slot="title"> {{ orderInfo.orderSn }}</template>  {{ orderInfo.orderSn }}</a-tooltip></div>
     </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>订单阶段:</span> {{ orderInfo.orderCurrentStepVal }} </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
       <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>订单类型: </span> {{ orderInfo.orderTypeVal }} </a-col>

      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>下单来源:</span> {{ orderInfo.orderSourceVal }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>支付状态:</span> {{ orderInfo.paymentStateVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span>支付时间:</span> <b>{{ orderInfo.paymentDate }}</b> </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>订单状态:</span> {{ orderInfo.orderStateVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" class="Beyond-the-hidden"><span style="width:90px">接单时间:</span> <b>{{ orderInfo.orderTakeDate }}</b>
      </a-col>
      
     
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>提货方式:</span> {{ orderInfo.pickupTypeVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>提货状态:</span> {{ orderInfo.pickupStateVal }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" class="Beyond-the-hidden"><span style="width:90px">下单时间:</span> <b>{{ orderInfo.createDate }}</b>
      </a-col>
      <a-col  v-if="orderInfo.orderState !=3"  :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 运输状态:</span> {{ orderInfo.transportStateVal }}</a-col>
    </a-row>
    <a-row v-if="orderInfo.orderState !=3"  :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>派货方式:</span> {{ orderInfo.dispatchTypeVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>派送状态:</span> {{ orderInfo.dispatchStateVal }}</a-col>
    </a-row>

    <a-row v-if="orderInfo.orderState !=3" :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 签收时间:</span><b> {{ orderInfo.signDate }}</b></a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 取货时间:</span><b> {{ orderInfo.pickupDate }} </b></a-col>
    </a-row>

    <a-row v-for="(item, index) in orderPaymentList" v-if="orderInfo.orderShow" :key="index" :gutter="24" type="flex" style="margin-top: 20px" class="orderPayment">
      <a-col :xl="6" :lg="6" :md="24" :sm="24" :xs="24"><span>支付金额：</span> <div>{{ item.actualPrice }} </div></a-col>
      <a-col :xl="8" :lg="8" :md="24" :sm="24" :xs="24" v-if="orderInfo.orderType==1 && orderInfo.paymentWay != 1 && orderInfo.paymentState==0"><span>支付状态：</span> <div> 待确认 </div></a-col>
      <a-col :xl="8" :lg="8" :md="24" :sm="24" :xs="24" v-if="!(orderInfo.orderType==1 && orderInfo.paymentWay != 1 && orderInfo.paymentState==0)"><span>支付状态：</span> <div> {{ item.paymentStateVal }} </div></a-col>

      <a-col :xl="6" :lg="6" :md="24" :sm="24" :xs="24"><span>退款金额:</span> <div>{{ item.refundPrice }}</div> </a-col>
      <a-col :xl="4" :lg="4" :md="24" :sm="24" :xs="24">
        <a
          v-if="(orderInfo.orderTakingBool || orderInfo.orderDiffBool) && item.paymentType==1 && item.paymentState==2"
          @click="refund(item.id)"
          >部分退款</a
        >
        <a
          v-if="item.orderRelation == 2 && item.paymentState != 2 && item.cancelType == 0"
          @click="cancel(item)"
          :paymentSn="item.paymentSn"
          >取消</a
        >
      </a-col>
    </a-row>
  <div class="order-footer-link">
    <br />
    <a v-if="orderInfo.orderType == 1 && orderInfo.pickupBills !=''" @click="ladingBill">点击查看提货单</a>
    
    <a v-if="orderInfo.orderType == 1 && orderInfo.orderShow" @click="logisticsShow">物流信息</a>
 
    
    <a v-if="orderInfo.orderType == 1 && orderInfo.paymentState == 2 && orderInfo.orderShow" @click="orderStub()">电子存根</a>
    
    
    <a v-if="orderInfo.orderType == 1 && orderInfo.paymentState == 2 && orderInfo.orderShow" @click="orderVerfity()">审核记录</a>
  </div>
    <refundPrice ref="refundPriceModal" @ok="handleOk" />
    <lading-bill ref="billModal" />

    <a-modal v-model="orderStubVisbile" title="电子存根" @ok="orderStubConfirm" :width="700">
      <div layout="inline" class="form">
        <a-card title="货物信息:" style="width: 700px" :bordered="false" >
          <a-row :gutter="24" type="flex">
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 货物名称:</span> {{ goodsInfo.goodsName }} </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>温区:</span> {{ goodsInfo.temperatureZoneVal }} </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>数量:</span> {{ goodsInfo.goodsNum }} </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>重量:</span> {{ goodsInfo.weight }} </a-col>
          </a-row>
          <a-row :gutter="24" type="flex" class="remark">
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 体积: </span>{{ goodsInfo.totalVolume }} </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>备注:</span>
              <div> <a-tooltip><template slot="title">{{ goodsInfo.goodsRemark }}</template> {{ goodsInfo.goodsRemark }}</a-tooltip></div>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="其它信息:" style="width: 800px" :bordered="false" >
          <a-row :gutter="24" type="flex" >
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 付款方式:</span> {{ orderInfo.paymentWayVal }}
            </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>提货方式:</span> {{ orderInfo.pickupTypeVal }}
            </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>派件方式:</span> {{ orderInfo.dispatchTypeVal }}
            </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 增值服务:</span> {{ orderInfo.receiptTypeVal }}
            </a-col>
          </a-row>
        </a-card>
        <a-card title="计费信息:" style="width: 800px" :bordered="false" >
          <a-row :gutter="24" type="flex" style="margin-top: 10px" class="billing">
            <a-col v-for="(item,index) in orderPriceList" :key="index" :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>{{ item.title}}: </span>{{ item.priceVal}} </a-col>          
          </a-row>
        </a-card>
        <a-row :gutter="24" type="flex" style="margin-top: 10px;text-align: right;width:100% !important;">
          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24" style="width:95%"><span style="color:red;margin-right:10px"> 总计:</span>{{ orderInfo.actualPrice }} </a-col>
        </a-row>
      </div>
    </a-modal>
    
    <Verfity ref="VerfityModal"/>
    <Logistics ref="LogisticsModal"/>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import LadingBill from '../modules/LadingBill'
import RefundPrice from '../modules/RefundPrice'
import Verfity from '../modules/Verfity'
import Logistics from '../modules/Logistics'
import { cancelPayment } from '@/api/order'

export default {
  name: 'OrderForm',
  components: {
    LadingBill,
    RefundPrice,
    Verfity,
    Logistics,
  },
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderInfo: {},
      goodsInfo: {},
      orderStubVisbile: false, //电子存根
      orderPaymentList: {},
      orderPriceList: [],
    }
  },
  methods: {
    load(record) {
      this.orderInfo = record.orderInfo
      this.goodsInfo = record.goodsInfo
      this.orderPaymentList = record.orderPaymentInfo
      this.orderPriceList = record.priceDetailList
      
    },

    ladingBill() {
      this.$refs.billModal.bill( this.orderInfo.pickupBillsArray )
    },
    refund(paymentId) {
      this.$refs.refundPriceModal.refundPrice(this.orderInfo, paymentId)
    },
    orderStub() {
      this.orderStubVisbile = true
    },

    orderStubConfirm() {
      this.orderStubVisbile = false
    },
    cancel(item) {
      var _this = this
      this.$confirm({
        title: '取消订单',
        content: '取消差价订单?',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk() {
          var param = { orderId: item.orderId, id: item.id }
          return cancelPayment(param).then((res) => {
            if (res.code == 0) {
              _this.$notification.success({
                message: '取消差价订单',
                description: `操作成功`,
              })
              _this.$parent.$parent.$parent.$parent.initData()        
            } else {
              _this.$notification.error({
                message: '取消差价订单',
                description: res.message,
              })
            }
          })
        },
        onCancel() {},
      })
    },
    logisticsShow(){
      this.$refs.LogisticsModal.logistics( this.orderInfo.id )
    },
    handleOk() {      
      this.$parent.$parent.$parent.$parent.initData()      
    },
    orderVerfity(){
          this.$refs.VerfityModal.verfity(this.orderInfo.id)
    }
  },
}
</script>
