 <style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
/deep/ .ant-row-flex{
  min-height: 45px;
  line-height: 45px;
  >div  >span{
    width: 110px !important;
  }
}
.Beyond-the-hidden{
  padding-right: 0 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  b{
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
  <a-form layout="inline" style="width: 100%" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>调度人: </span> {{ dispatchData.dispacthUser }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 司机信息:</span> {{ dispatchData.driver }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 司机电话:</span><b>{{ dispatchData.driverTel }} </b> </a-col>
      <a-col v-show="dispatchData.orderType == 1" :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span>预计上门时间:</span><b>{{ dispatchData.planStartDate }}</b></a-col>
      <a-col v-show="dispatchData.orderType == 2" :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 车牌号:</span> <b>{{ dispatchData.vno }}</b> </a-col>
    </a-row>
    
    <a-row v-show="dispatchData.orderType == 1" :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span>仓库信息:</span><b>{{ dispatchData.warehouse }} </b> </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>入库人:</span> {{ dispatchData.InWarehouseCreator }}</a-col>
    </a-row>

    <a-row v-show="dispatchData.orderType == 1" :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>入库方式:</span> {{ dispatchData.dispatchTypeVal }}</a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"class="Beyond-the-hidden"><span>入库时间:</span> <b>{{ dispatchData.inWarehouseDate }}</b></a-col>
    </a-row>
  </a-form>
</template>    
<script>
export default {
  name: 'StorageForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      dispatchData: {},
    }
  },
  methods: {
    load(res) {
      this.dispatchData = res
    },
  },
}
</script>
