<style lang="less" scoped >
/deep/ .ant-form-item-label {
  text-align: left !important;
  width: 100px;
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>
<template>
  <a-modal
    key="transport"
    title="调度"
    :width="500"
    :visible="$parent.visible_dispatch_tlt"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    :closable="true"
    @cancel="dispatchHandleCancel"
    :centered="true"
    :mask="true"
    :footer="null"
    :maskClosable="false"
    :body-style="{ height: 'auto' }"
  >
    <div>
      <a-form @submit="handleSubmit" :form="form">
        <a-form-item
          label="司机"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-select
            placeholder="请选择司机"
            @change="handleChange2"
            v-decorator="['driverId', { rules: [{ required: true, message: '请选择司机' }] }]"
          >
            <a-select-option v-for="(item, index) in optionsDriverList" :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="司机姓名"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['name', { rules: [{ required: true, message: '请选择司机' }] }]"
            read-only="true"
            name="name"
          />
        </a-form-item>
        <a-form-item
          label="司机电话"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['mobile', { rules: [{ required: true, message: '请选择司机' }] }]"
            read-only="true"
            name="mobile"
          />
        </a-form-item>

        <a-form-item
          label="车牌号"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
        >
          <a-select placeholder="请选择车辆" @change="handleChange3" v-decorator="['vehicleId']">
            <a-select-option v-for="(item, index) in optionsVehicleList" :key="index" :value="item.id">
              {{ item.vno }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
          <a-button style="margin-right: 8px" @click="dispatchHandleCancel">取消</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment'
import { refundPriceOperate } from '@/api/order'
import {findListDriver, findListVehicle, findListWarehouse, loadDispacth, tltTransportNo} from '@/api/manage'

export default {
  name: 'tltDispatchDialog',
  data() {
    return {
      form: this.$form.createForm(this),
      confirmLoading: false,
      newDriver: true, //新司机，只有当选择手动录入司机是司机姓名电话可编辑
      optionsDriverList: [],
      optionsVehicleList: [],
      driverInfoList: [],
      vehicleInfoList: [],
      vehicleId:null
    }
  },

  methods: {
    handleSubmit: function (e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = []
          values.planStartDate = moment(values.planStartDate).format('YYYY-MM-DD HH:mm:ss')
          values.orderIds.push(this.$parent.orgOrderId - 0)
          values.type = 2
          values.driver = values.name
          values.driverTel = values.mobile
          values.vehicleId = values.vehicleId
          console.log('Received values of form: ', values)
          tltTransportNo(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('更新成功，如需要请在列表页点击查询按钮，刷新数据')
                that.$parent.visible_dispatch_tlt = false
                //关闭路由
                that.$multiTab.closeCurrentPage()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    dispatchHandleCancel: function () {
      this.$parent.visible_dispatch_tlt = false
    },
    handleChange2(value) {
      if (value == 0) {
        this.newDriver = true
      } else {
        this.newDriver = false
        let selectedDriver = {}
        for (let i in this.driverInfoList) {
          if (this.driverInfoList[i].id == value) {
            selectedDriver = this.driverInfoList[i]
            break
          }
        }

        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile: selectedDriver.mobile,
        })
      }
    },
    handleChange3(value) {
      let selectedVehicle = {}
      for (let i in this.vehicleInfoList) {
        if (this.vehicleInfoList[i].id == value) {
          selectedVehicle = this.vehicleInfoList[i]
          break
        }
      }

      this.form.setFieldsValue({
        vehicleId: selectedVehicle.id + '',
      })
    },
  },
  mounted() {},
  created() {
    let that = this
    this.tabObj = {}
    //初始化司机列表和仓库列表
    findListDriver({ status: 1 }).then((res) => {
      if (res != null && res.result != null) {
        that.optionsDriverList = []
        that.driverInfoList = res.result
        for (let i in res.result) {
          that.optionsDriverList.push({
            id: res.result[i].id + '',
            name: res.result[i].name + '(' + res.result[i].mobile + ')',
          })
        }
      }
    })
    //查询车辆列表
    findListVehicle({ status: 1 }).then((res) => {
      if (res != null && res.result != null) {
        that.optionsVehicleList = []
        that.vehicleInfoList = res.result
        for (let i in res.result) {
          that.optionsVehicleList.push({ id: res.result[i].id + '', vno: res.result[i].vno })
        }
      }
    })
  },
}
</script>