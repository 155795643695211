 <style lang="less" scoped>
    .ant-modal-body{
      .ant-row-flex >div{
        min-height: 50px;
        line-height: 30px;
        display: flex;
        >span{
          display: inline;
          width: 90px;
          font-size: 16px;
          color: #000;
        }
        >div{
          display: inline;
          line-height: 30px;
          width: 280px;
          >span{
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          }
        }
      }
      .ant-row-flex:nth-of-type(n+2){
        border-top: 1px solid #e8e8e8;
        padding-top: 10px;
      }
    }
 </style>
  <template>
    <a-modal
        title="审核记录"
        :width="800"
        :visible="visible"       
        @cancel="handleCancel"
    >
     <div class="table-operator">
         <a-row v-if="verfityList.length != 0" v-for="(item,index) in verfityList" :key="index" :gutter="24" type="flex" >        
              <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                  <span>申请原因：</span>
                  <div>
                    <a-tooltip placement="topLeft">
                    <template slot="title">
                      {{ item.applyForCause }}
                    </template>
                    {{ item.applyForCause }}
                  </a-tooltip>
                  </div>
              </a-col>
              <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                   <span>申请日期：</span>{{ item.createDate }}
              </a-col>
               <a-col v-if="item.auditState == 3"  :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                    <span>驳回原因：</span><div> <a-tooltip placement="topLeft">
                    <template slot="title">
                     {{ item.auditRemark }}
                    </template>
                    {{ item.auditRemark }}
                  </a-tooltip></div>
              </a-col>
              <a-col v-if="item.auditState == 3"  :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                    <span>驳回日期：</span> {{ item.auditDate }}
              </a-col>
              <a-col v-if="item.auditState == 2"  :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 审核通过</span> </a-col>
              <a-col v-if="item.auditState == 2"  :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 通过日期：</span> {{ item.auditDate }}</a-col>
         </a-row>  
<!--          
          <a-row v-if="verfityList.length != 0" v-for="(item,index) in verfityList" :key="index" :gutter="24" type="flex" >        
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                  <span>申请原因：</span>{{ item.applyForCause }}
              </a-col>
              <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                   <span>申请日期：</span>{{ item.createDate }}
              </a-col>   

               <a-col v-if="item.auditState == 3" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                    <span>驳回原因：</span>{{ item.auditRemark }}
              </a-col>
              <a-col v-if="item.auditState == 3" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                    <span>驳回日期：</span> {{ item.auditDate }}
              </a-col>
               <a-col v-if="item.auditState == 2" :xl="12" :lg="24" :md="24" :sm="24" :xs="24"> 审核通过 </a-col>
         </a-row>    -->
         <a-row v-if="verfityList.length == 0">
            暂无
         </a-row>     
      </div>
     <template slot="footer">    
      <a-button key="forward"  type="primary" @click="confirm()">确定</a-button>
    </template>  
  </a-modal>
</template>  

<script>
import { verfityList } from '@/api/order'
export default {
  name: 'Verfiy',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      verfityList:{},           
    }
  },
  methods: {
    verfity (orderId) {
        var param = { orderId: orderId }
        return verfityList(param).then((res) => {
          console.log('verfityList',res)
          if (res.code == 0) {
            this.verfityList = res.result
            this.visible = true
          } else {
            this.$notification.error({
              message: '审核记录',
              description: res.message,
            })
          }
        })
     
    },
    confirm () {
        this.visible = false
    },
    handleCancel () {    
      this.visible = false     
    }
  }
  
}
</script>