import { Checkbox } from 'antd';
<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        
        <a-row :gutter="24">
            <a-col :span="24">
                   <a-form-item v-show="false">
                      <a-input v-decorator="['orderId']"   /> M³
                    </a-form-item>
                  <a-form-item
                    label="备注"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                  >
                    <a-textarea name='applyForCause'  :rows="2"  v-decorator="['applyForCause', {rules: [{required: true,message:'备注'}]}]" :maxLength="150"></a-textarea>                                   
                  </a-form-item>
              </a-col>
        </a-row>
       
      </a-form>
    </a-spin>
    <template slot="footer">
      <a-button key="cancel" @click="handleCancel">取消</a-button>
      <a-button key="forward" :loading="confirmLoading" type="primary" @click="handleNext" style="margin-right:10px">保存</a-button>
    </template>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { launchSecConfirm } from '@/api/order'


export default {
  name: 'launchSecConfirm',
  data () {
    return {     
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },    
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,      
      orderInfo:{},
      title:"",
      form: this.$form.createForm(this)     
    }
  },
 
  methods: {
    secondaryOrderConfirm (orderInfo) {     
      this.visible = true            
      this.form.resetFields() 
      const { form: { setFieldsValue } } = this
      var orderId = orderInfo.id;
      this.orderInfo = orderInfo;
      this.title = "二次确认申请"
      this.$nextTick(() => {            
            setFieldsValue(pick({"orderId":orderId}, ['orderId']))
      })         
    },
    handleNext () {
      const { form: { validateFields } } = this      
      // last step
      let that = this
      that.confirmLoading = true
      validateFields((errors, values) => {             
        if (!errors) {         
         return launchSecConfirm(values)
         .then(res => {
            if(res.code == 0){              
              that.$notification.success({
                message: that.title,
                description: `操作成功`
              })              
              that.visible = false
              that.confirmLoading = false              	
            
              that.$emit('ok')              
            }else{
              that.$notification.error({
                message: that.title,
                description: res.message
              })
              that.confirmLoading = false
            }
          })
        } else {
          that.confirmLoading = false
        }
        
      })
    },
    handleCancel () {          
      this.visible = false          
    }    
  }
}
</script>
<style lang="less" scoped>
 /deep/ .ant-form-item-label{
    width: 100px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper{
    width: 81.8%;
  }
</style>