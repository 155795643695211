import { Checkbox } from 'antd';
<template>
  <a-modal
    title="部分退款"
    :width="550"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        
       <a-row :gutter="24">
          <a-col :span="24">
             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['orderId']"   />
             </a-form-item>  

             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['customerId']"   />
             </a-form-item> 
              <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['paymentId']"   />
             </a-form-item>  
             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['refundPrice']"   />
             </a-form-item>                                          
            <a-row>
                 <a-col :span="12">
                      <a-form-item
                        label="提货费退款"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                       
                      >
                      <a-input-number :min="0"  @blur="computeRefundPrice"
                              :max="99999"
                              :step="0.01"
                              :precision="2" name='pickupPrice' v-decorator="['pickupPrice', {rules: [{required: true, message: '请输入提货费退款'}]}]"/>                                   
                      </a-form-item>
                 </a-col>

                 <a-col :span="12">
                      <a-form-item
                        label="运费退款"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        
                      >
                      <a-input-number :min="0" @blur="computeRefundPrice"
                              :max="99999"
                              :step="0.01"
                              :precision="2" name='transportPrice' v-decorator="['transportPrice', {rules: [{required: true, message: '请输入运费退款'}]}]"/>                                   
                      </a-form-item>
                 </a-col>
            </a-row>
           
          </a-col>          
        </a-row>

         <a-row>
                 <a-col :span="12">
                      <a-form-item
                        label="交仓费退款"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        
                      >
                      <a-input-number :min="0" @blur="computeRefundPrice"
                              :max="99999"
                              :step="0.01"
                              :precision="2" name='warehouseInFee' v-decorator="['warehouseInFee', {rules: [{required: true, message: '请输入交仓费退款'}]}]"/>                                   
                      </a-form-item>
                 </a-col>

                 <a-col :span="12">
                      <a-form-item
                        label="派费退款"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                       
                      >
                      <a-input-number :min="0"  @blur="computeRefundPrice"
                              :max="99999"
                              :step="0.01"
                              :precision="2" name='dispatchPrice' v-decorator="['dispatchPrice', {rules: [{required: true, message: '请输入派费退款'}]}]"/>                                   
                      </a-form-item>
                 </a-col>                    
        </a-row>
        
        
    
        <a-row :gutter="24">
          <a-col :span="24">                                                   
            <a-row>
              <a-form-item
                label="备注"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
              >
               <a-textarea name='refundRemark' :rows="4" v-decorator="['refundRemark', {rules: [{required: true,message:'备注'}]}]" :maxLength="150"></a-textarea>                                   
              </a-form-item>
            </a-row>   
            <a-row>
                 <div class="table-operator">
                      <span><a>退款总计</a>:{{ refundPriceVal }}  元</span> 
                 </div>
            </a-row>        
          </a-col>
          
        </a-row>

      </a-form>
    </a-spin>
    <template slot="footer">
      <a-button key="cancel" @click="handleCancel">取消</a-button>
      <a-button key="forward" :loading="confirmLoading" type="primary" @click="handleNext" style="margin-right:8px">保存</a-button>
    </template>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { refundPriceOperate } from '@/api/order'


export default {
  name: 'refundPriceOperate',
  data () {
    return {     
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },    
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,     
      refundPriceVal:0,       
      form: this.$form.createForm(this)     
    }
  },
 
  methods: {
    refundPrice(orderInfo,paymentId) {     
      console.log('orderInfo',orderInfo)   
      this.visible = true            
      this.form.resetFields() 
      const { form: { setFieldsValue } } = this
      this.$nextTick(() => {            
            setFieldsValue(pick({"orderId":orderInfo.id,"customerId":orderInfo.customerId,"paymentId":paymentId}, ['orderId','customerId','paymentId']))
      })         
    },
    computeRefundPrice(){
       let pickupPrice = this.form.getFieldValue('pickupPrice')
         let transportPrice = this.form.getFieldValue('transportPrice')
         let dispatchPrice = this.form.getFieldValue('dispatchPrice')
         let warehouseInFee = this.form.getFieldValue('warehouseInFee')
         if(pickupPrice == null || pickupPrice == undefined){
           pickupPrice = 0;
         }
         if(transportPrice == null || transportPrice == undefined){
           transportPrice = 0;
         }
         if(dispatchPrice == null || dispatchPrice == undefined){
           dispatchPrice = 0;
         }
         if(warehouseInFee == null || warehouseInFee == undefined){
           warehouseInFee = 0;
         }       
         console.log('pickupPrice',pickupPrice)  
         this.refundPriceVal = pickupPrice + transportPrice + dispatchPrice + warehouseInFee
         this.refundPriceVal = parseFloat(this.refundPriceVal).toFixed(2)  
         this.form.setFieldsValue({'refundPrice':this.refundPriceVal}) 
    },
    handleNext () {       
      const { form: { validateFields } } = this
      // last step
      this.confirmLoading = true
      validateFields((errors, values) => {
        console.log('errors:', errors, 'val:', values)             
        if (!errors) {  
         return refundPriceOperate(values)
         .then(res => {
            if(res.code == 0){
              this.$notification.success({
                message: '部分退款',
                description: `成功`
              })
              this.visible = false
              this.confirmLoading = false
              this.$emit('ok')                                   	              
            }else{
              this.$notification.error({
                message: '部分退款',
                description: res.message
              })
              this.confirmLoading = false
            }
          })
        } else {
          this.confirmLoading = false
        }
        
      })
    },
    handleCancel () {          
      this.visible = false          
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label{
  text-align: left;
  width: 45%;
}
/deep/ .ant-form-item-control-wrapper{
    width: 80%;
  }
  /deep/ .ant-input-number{
    width: 50%;
  }
</style>