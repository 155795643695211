<style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
/deep/ .ant-row-flex {
  min-height: 40px;
  line-height: 40px;
}
</style>
<template>
  <a-form layout="inline" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>物品名称:</span> {{ goodsInfo.goodsName }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>总件数:</span> {{ goodsInfo.goodsNum }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 总重量:</span> {{ goodsInfo.weight }} KG</a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 总体积:</span> {{ goodsInfo.volume }} M³</a-col>
    </a-row>
  </a-form>
</template>
<script>
export default {
  name: 'GoodsForm',
  components: {},
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goodsInfo: {},
    }
  },
  mounted() {},
  methods: {
    load(record) {
      this.goodsInfo = record
    },
  },
}
</script>
