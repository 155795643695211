 <style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
/deep/ .ant-row-flex{
  min-height: 45px;
  line-height: 45px;
  >div  >span{
    width: 110px !important;
  }
}
.Beyond-the-hidden{
  padding-right: 0 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  b{
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
  <a-form layout="inline" style="width: 100%" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>发起人: </span> {{ orderConfirmData.createName }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 备注:</span> {{ orderConfirmData.applyForCause }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 确认人:</span>{{ orderConfirmData.auditUserName }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 备注:</span> {{ orderConfirmData.auditRemark }} </a-col>      
    </a-row>
        
  </a-form>
</template>    
<script>
export default {
  name: 'orderConfirmForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      orderConfirmData: {},
    }
  },
  methods: {
    load(res) {
      this.orderConfirmData = res
    },
  },
}
</script>
