<style lang="less" scoped>
/deep/ #depositReceiptUrl,
#pratiqueUrl,
#nucleinUrl {
  width: 128px;
  height: 128px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-form-item1 {
  min-height: 0 !important;
  width: auto !important;
}
/deep/ .ant-form-item {
  margin-right: 0 !important;
}
.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-row-flex {
    width: 100% !important;
    min-height: 45px;
    line-height: 45px;
    margin-right: 0 !important;
    /deep/ .ant-radio-wrapper {
      margin-right: 0 !important;
    }
    >div >span{
      display: inline-block;
      width: 100px;
      color: #000;
      font-size: 16px;
    }
  }
  .orderPayment{
    margin-left: 0 !important;
    display: flex;
    > div{
      min-height: 45px;
      line-height: 45px;
      display: flex;
      padding: 0 !important;
      >span{
        display: inline-block;
        width: 75px !important;
        font-size: 14px !important;
      }
    }
  }
}
.multi-form {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
}
/deep/ .ant-form-item-control-wrapper {
  width: 65%;
}
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}
/deep/ .ant-radio-wrapper-checked {
  > span:nth-child(2) {
    padding-left: 0 !important;
  }
}
.order-footer-link{
  width: 100%;
  margin: 30px 30px 20px 0;
  box-sizing: border-box;
  a{
    margin-right: 10px;
  }
}
.ant-modal-body{
  .ant-row-flex{
     >span:nth-child(1){
      display: inline-block;
      width: 90px;
      color: #000;
      font-size: 16px;
    }
  }
}
.ant-row-flex >div{
  padding-right: 0 !important;
}
.billing{
  display: flex;
  flex-wrap: wrap;
  >div{
    height: 45px !important;
  }
}
/deep/ .ant-card-head{
  padding: 0 !important;
}
/deep/ .ant-card-body {
    padding: 0 24px !important;
}
.remark{
    >div{
    display: flex;
    font-size: 12px;
    >div {
    width: 64%;
    >span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    }
     span{
      font-size: 14px;
     }
    }
}
.Beyond-the-hidden{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
 >div,
  b{
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
     <a-modal v-model="quoteDetailVisbile" title="报价明细"  :width="850"  footer="">
      <div layout="inline" class="form">
       
        <a-card title="基础费用:" style="width: 800px" :bordered="false" >
          <a-row :gutter="24" type="flex">
            <a-col :xl="12" v-for="(item,index) in priceDetailList.basics" :lg="12" :md="24" :sm="24" :xs="24"><span> {{ item.title }}:</span> {{ item.priceVal }} </a-col>           
          </a-row>         
        </a-card>

        <a-card title="收入:" style="width: 800px" :bordered="false" >
          <a-row :gutter="24" type="flex" >
              <a-col :xl="12" v-for="(item,index) in priceDetailList.income" :lg="12" :md="24" :sm="24" :xs="24"><span> {{ item.title }}:</span> {{ item.priceVal }} </a-col>           
          </a-row>
        </a-card>

        <a-card title="优惠:" style="width: 800px" :bordered="false" >
            <a-row :gutter="24" type="flex" >
               <a-col :xl="12" v-for="(item,index) in priceDetailList.discounts" :lg="12" :md="24" :sm="24" :xs="24"><span> {{ item.title }}:</span> {{ item.priceVal }} </a-col>           
            </a-col>           
          </a-row>
        </a-card>

          <a-card title="退款:" style="width: 800px" :bordered="false" >
            <a-row :gutter="24" type="flex" >
                <a-col :xl="12" v-for="(item,index) in priceDetailList.refund" :lg="12" :md="24" :sm="24" :xs="24"><span> {{ item.title }}:</span> {{ item.priceVal }} </a-col>           
            </a-col>           
          </a-row>
        </a-card>

          <a-card title="补差:" style="width: 800px" :bordered="false" >
            <a-row :gutter="24" type="flex" >
                <a-col :xl="12" v-for="(item,index) in priceDetailList.difference" :lg="12" :md="24" :sm="24" :xs="24"><span> {{ item.title }}:</span> {{ item.priceVal }} </a-col>           
            </a-col>           
          </a-row>
        </a-card>

         <a-row :gutter="24" type="flex" style="margin-top: 10px;text-align: right;width:100% !important;">
          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24" style="width:95%"><span style="color:red;margin-right:10px"> 总计:</span>{{ priceDetailList.actualPrice }} </a-col>
        </a-row>
      
      </div>
    </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import { quoteDetail } from '@/api/order'


export default {
  name: 'quoteDetail',
  data () {
    return {     
      labelCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },    
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      quoteDetailVisbile: false,
      priceDetailList:[],            
      form: this.$form.createForm(this)     
    }
  },
 
  methods: {
    quoteDetail (orderId) {                    
      let that = this;
      var param = {orderId:orderId}
      quoteDetail(param)
        .then((res) => {
          console.log('res',res)
          if (0 == res.code) {              
              this.priceDetailList = res.result
              console.log('income',this.priceDetailList.income)
              this.quoteDetailVisbile = true
          }else{
             this.$notification.error({
              message: "获取报价明细",
              description: res.message,
            })
          }
        }).catch((e) => {
          console.info(e)
        })

    },    
    handleCancel () {          
      this.quoteDetailVisbile = false          
    }
  }
}
</script>
<style lang="less" scoped>
   /deep/ .ant-form-item-label{
    width: 100px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper{
    width: 85.5%;
  }
  /deep/ .ant-input-number{
    width: 50%;
  }
</style>