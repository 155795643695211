import { Checkbox } from 'antd';
<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24">
             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['orderId']"   /> M³
             </a-form-item>
            <a-row>
              <a-form-item
                label="备注"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
              >
               <a-textarea name='auditRemark' placeholder="备注150字内"  :rows="2"  v-decorator="['auditRemark', {rules: [{required: true,message:'备注'}]}]" :maxLength="150"></a-textarea>                                   
              </a-form-item>
            </a-row>
           

          </a-col>
          
        </a-row>
       
      </a-form>
    </a-spin>
    <template slot="footer">
      <a-button key="cancel" @click="handleCancel">取消</a-button>
      <a-button key="forward" :loading="confirmLoading" type="primary" @click="handleNext" style="margin-right:10px">已二次确认可入库</a-button>
    </template>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { orderSecConfirm } from '@/api/order'


export default {
  name: 'orderSecConfirm',
  data () {
    return {     
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },    
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,      
      orderInfo:{},
      title:"",
      form: this.$form.createForm(this)     
    }
  },
 
  methods: {
    orderSecConfirm (orderInfo) {     
      this.visible = true            
      this.form.resetFields() 
      const { form: { setFieldsValue } } = this
      var orderId = orderInfo.id;
      this.orderInfo = orderInfo;
      this.title = "确认完成"
      this.$nextTick(() => {            
            setFieldsValue(pick({"orderId":orderId}, ['orderId']))
      })         
    },
    handleNext () {
      const { form: { validateFields } } = this
      
      // last step
      this.confirmLoading = true
      validateFields((errors, values) => {            
        if (!errors) {         
         return orderSecConfirm(values)
         .then(res => {
            if(res.code == 0){
              this.$notification.success({
                message: this.title,
                description: `操作成功`
              })
              this.visible = false
              this.confirmLoading = false              	
              this.$emit('ok')              
            }else{
              this.$notification.error({
                message: this.title,
                description: res.message
              })
              this.confirmLoading = false
            }
          })
        } else {
          this.confirmLoading = false
        }
        
      })
    },
    handleCancel () {          
      this.visible = false          
    }    
  }
}
</script>
<style lang="less" scoped>
 /deep/ .ant-form-item-label{
    width: 100px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper{
    width: 81.8%;
  }
</style>