<template>
  <div layout="inline" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"><span> 收件人:</span> {{ orderInfo.receiverName }} </a-col>
      <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
        <span>联系方式:</span> {{ orderInfo.receiverPhoneNumber }}
      </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px" class="remark">
      <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24"><span>收件人地址: </span>
        <div> <a-tooltip placement="bottomLeft"><template slot="title">{{ orderInfo.receiverAddressDetail }}</template> {{ orderInfo.receiverAddressDetail }}</a-tooltip></div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import pick from 'lodash.pick'
export default {
  name: 'RecipientForm',
  props: {
    
  },
  data() {
    return {
      orderInfo: {},
    }
  },
  methods: {
    load(record) {      
        this.orderInfo = record
    },
  },
}
</script>
<style lang="less" scoped>
.form {
  /deep/ .ant-row-flex {
    > div {
      min-height: 40px !important;
      line-height: 40px;
    }
  }
}
.remark{
    >div{
    display: flex;
    font-size: 12px;
    >div {
    width: 68%;
    >span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    }
     span{
      font-size: 14px;
     }
    }
  }
</style>