 <style lang="less" scoped>
 .form{
  >div:nth-child(1),
  >div:nth-child(2){
    display: flex;
    flex-direction: column;
  }  
 }
  .table-operator {
    margin-top: 20px;
  }
  /deep/ .ant-form-item-label {
    width: 100px;
    text-align: left !important;
    margin-right: 10px;
    > label {
      display: inline-block;
      width: 100px;
      color: #000;
      font-size: 16px;
    }
  }

  /deep/  .ant-form-item-control{
  span {
        width: 100% !important;
      }
  }
  .truck-loading{
    display: flex;
    flex-wrap: wrap;
    >div{
      border: 1px dashed #d9d9d9;
      border-radius: 5px;
    }
    img{
      padding: 8px;
      box-sizing: border-box;
    }
  }
  .form-abnormal{
    display: flex;
    flex-direction: column;
    .ant-row-flex{
      word-break:break-all;
      word-wrap:break-word;
      white-space: normal;
      border-bottom: 1px solid #d9d9d9;
    }
    .abnormal-describe{
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    }
  }
</style>
<template>
  <a-form layout="inline" style="width: 100%" :form="form" class="form">
    <a-form-item label="装车照片" style="width: 100%" >
      <a-row class="truck-loading">
        <a-col style="margin:0 20px 20px 0 " v-for="(item, index) in pickUpPicList" :key="index">
          <img @click="imgShow($event)" :src="item.pic" alt="head-photo" height="150" width="200" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="签收单" style="width: 100%; margin-top: 20px">
      <a-row class="truck-loading">
        <a-col style="margin:0 20px 20px 0 " v-for="(item, index) in arrivalPicList" :key="index">
          <img @click="imgShow($event)" :src="item.pic" alt="head-photo" height="150" width="200" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="异常" style="margin-top: 20px" class="form-abnormal">
      <a-row v-for="(item, index) in orderExceptionInfoList" :key="index" :gutter="24" type="flex">

        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <div class="abnormal-describe">{{ item.info }}</div>
            <a-row class="truck-loading">
              <a-col v-for="(item, indexN) in orderExceptionInfoList[index].orderExceptionPicList" style="margin:0 20px 20px 0 " :key="indexN">
                <img @click="imgShow($event)" :src="item.pic" alt="head-photo" height="150" width="200" />
              </a-col>
            </a-row>
        </a-col>
      </a-row>
    </a-form-item>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-form>
 

</template>    
<script>
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  name: 'DriverForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      pickUpPicList: [],
      arrivalPicList: [],
      orderExceptionInfoList: [],

      previewImage:"",
      previewVisible:false,
      form: this.$form.createForm(this),
    }
  },

  methods: {
    load(pickUpPicList, arrivalPicList, orderExceptionList) {
      this.pickUpPicList = pickUpPicList
      this.arrivalPicList = arrivalPicList
      this.orderExceptionInfoList = orderExceptionList

      
      for (var i = 0; i < pickUpPicList.length; i++) {
        var fileName = pickUpPicList[i].pic
        let url = '/service_provider/upload/getPic?fileName=' + fileName
        this.getimgblob(url, i, 'pic')
      }

      for (var i = 0; i < arrivalPicList.length; i++) {
        var fileName = arrivalPicList[i].pic
        let url = '/service_provider/upload/getPic?fileName=' + fileName
        this.getimgblob(url, i, 'arrival')
      }

      for (var i = 0; i < orderExceptionList.length; i++) {                       
        for(var j=0; j<orderExceptionList[i].orderExceptionPicList.length; j++){
               var fileName = orderExceptionList[i].orderExceptionPicList[j].pic               
               let url = '/service_provider/upload/getPic?fileName=' + fileName
               this.getimgblob(url, i, 'exception',j)
        }       
      }
      console.log('orderExceptionInfoList',this.orderExceptionInfoList)
    },
    imgShow($event){
         console.log($event.path[0].currentSrc);
        this.previewImage = $event.path[0].currentSrc
        this.previewVisible = true
    },
    async getimgblob(url, i, mark,j = 0) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          if (mark == 'pic') {
            this.pickUpPicList[i].pic = URL.createObjectURL(request.response)
          } else if (mark == 'arrival') {
            this.arrivalPicList[i].pic = URL.createObjectURL(request.response)
          }else if(mark == 'exception'){
            this.orderExceptionInfoList[i].orderExceptionPicList[j].pic = URL.createObjectURL(request.response)
          }
        }
      }
      request.send(null)
    },
    handleCancel(){
        this.previewVisible = false
    }
  },
}
</script>
