import { Checkbox } from 'antd';
<template>
  <a-modal
    title="补差价"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        
       <a-row :gutter="24">
          <a-col :span="24">
             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['orderId']"   /> M³
             </a-form-item>  

             <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['customerId']"   /> M³
             </a-form-item>   
              <a-form-item v-show="false" style="margin-left:20px;">
                 <a-input style="width:100px;" v-decorator="['diffPrice']"   /> M³
             </a-form-item>                                          
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="提货费差价"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                >
                <a-input-number :min="0" @blur="computeDiffPrice"
                        :max="99999"
                        :step="0.01"
                        :precision="2" name='pickupPrice' v-decorator="['pickupPrice', {rules: [{required: true, message: '请输入提货费差价'}]}]"/>                                   
                </a-form-item>
             </a-col> 

            <a-col :span="12">
                 <a-form-item
                label="运费差价"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
              >
              <a-input-number :min="0" @blur="computeDiffPrice"
                        :max="99999"
                        :step="0.01"
                        :precision="2" name='transportPrice' v-decorator="['transportPrice', {rules: [{required: true, message: '请输入运费差价'}]}]"/>                                   `
              </a-form-item>
             </a-col> 
            </a-row>           
          </a-col> 

          <a-col :span="24">             
            <a-row>
                   <a-col :span="12">                         
                      <a-form-item
                        label="派费差价"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                      >
                      <a-input-number :min="0" @blur="computeDiffPrice"
                              :max="99999"
                              :step="0.01"
                              :precision="2" name='dispatchPrice' v-decorator="['dispatchPrice', {rules: [{required: true, message: '请输入派费差价'}]}]"/>                                   
                      </a-form-item>
                 
                  </a-col>  
                  <a-col :span="12">   
                         <a-form-item
                          label="交仓费差价"
                          :labelCol="labelCol"
                          :wrapperCol="wrapperCol"
                        >
                        <a-input-number :min="0" @blur="computeDiffPrice"
                                :max="99999"
                                :step="0.01"
                                :precision="2" name='warehouseInFee' v-decorator="['warehouseInFee', {rules: [{required: true, message: '请输入交仓费差价'}]}]"/>                                   
                        </a-form-item>

                  </a-col>
            </a-row>           
          </a-col>  
                
        </a-row>
                    
        <a-row :gutter="24">
          <a-col :span="24">                                                   
            <a-row>
              <a-form-item
                label="备注"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
              >
               <a-textarea name='paymentRemark' :rows="4" v-decorator="['paymentRemark', {rules: [{required: true,message:'请输入备注'}]}]" :maxLength="150"></a-textarea>                                   
              </a-form-item>
            </a-row>    
            <a-row>
                 <div class="table-operator">
                      <span><a>差价总计</a>:{{ diffPriceVal }} 元</span> 
                 </div>
            </a-row>
          </a-col>          
        </a-row>
       
      </a-form>
    </a-spin>
    <template slot="footer">
      
      <a-button key="cancel" @click="handleCancel">取消</a-button>
      <a-button key="forward" :loading="confirmLoading" type="primary" @click="handleNext" style="margin-right:10px">保存</a-button>
    </template>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { diffPriceOperate } from '@/api/order'


export default {
  name: 'diffPriceOperate',
  data () {
    return {     
      labelCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },    
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,    
      diffPriceVal:0,
      form: this.$form.createForm(this)     
    }
  },
 
  methods: {
    diffPrice (orderInfo) {        
      this.visible = true            
      this.form.resetFields() 
      const { form: { setFieldsValue } } = this
      this.$nextTick(() => {            
            setFieldsValue(pick({"orderId":orderInfo.id,"customerId":orderInfo.customerId}, ['orderId','customerId']))
      })         
    },
    computeDiffPrice(){
         let pickupPrice = this.form.getFieldValue('pickupPrice')
         let transportPrice = this.form.getFieldValue('transportPrice')
         let dispatchPrice = this.form.getFieldValue('dispatchPrice')
         let warehouseInFee = this.form.getFieldValue('warehouseInFee')
         if(pickupPrice == null || pickupPrice == undefined){
           pickupPrice = 0;
         }
         if(transportPrice == null || transportPrice == undefined){
           transportPrice = 0;
         }
         if(dispatchPrice == null || dispatchPrice == undefined){
           dispatchPrice = 0;
         }
         if(warehouseInFee == null || warehouseInFee == undefined){
           warehouseInFee = 0;
         }         
         this.diffPriceVal = pickupPrice + transportPrice + dispatchPrice + warehouseInFee
         this.diffPriceVal = parseFloat(this.diffPriceVal).toFixed(2)  
         this.form.setFieldsValue({'diffPrice':this.diffPriceVal})            
    },
    handleNext () {
      const { form: { validateFields } } = this
      // last step
      this.confirmLoading = true
      validateFields((errors, values) => {                    
        if (!errors) {         
         return diffPriceOperate(values)
         .then(res => {
            if(res.code == 0){
              this.$notification.success({
                message: '补差价',
                description: `成功`
              })
              this.visible = false
              this.confirmLoading = false              	
              this.$emit('ok')              
            }else{
              this.$notification.error({
                message: '补差价',
                description: res.message
              })
              this.confirmLoading = false
            }
          })
        } else {
          this.confirmLoading = false
        }
        
      })
    },
    handleCancel () {          
      this.visible = false          
    }
  }
}
</script>
<style lang="less" scoped>
   /deep/ .ant-form-item-label{
    width: 100px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper{
    width: 85.5%;
  }
  /deep/ .ant-input-number{
    width: 50%;
  }
</style>