<style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
/deep/ .ant-form-item-label label{
      color: #000;
      font-size: 16px;
  }
.form{
  display: flex;
  flex-wrap: wrap;
  >div{
    min-height: 45px;
    line-height: 45px;
  }
   .ant-form-item {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
   /deep/ .ant-btn-primary{
    span{
    color: #fff !important;
    text-align: center;
    }
    }
   /deep/ .ant-form-item-children{
      width: 100% !important;
    }
  }
  .ant-form-item{
    margin-right: 0;
  }
  .ant-row-flex{
    width: 100%;
  }
  i{
    color: #000 !important;
  }
  .row-footer-freight{
    display: flex;
    flex-wrap: wrap;
    min-height: 45px;
    line-height: 45px;
    border-Top: 1px solid #e8e8e8;
    >div{
      width: 50%;
      >span{
        width: 100px !important;
      }
    }
  }
}
.price-detail .ant-modal-body p span{
  display: inline-block;
  width: 140px;
  height: 40px;
  line-height: 40px;
  color: #000;
  font-size: 16px;
}
.goodsFromWidth {
  /deep/ .ant-form-item-label{
  padding-left: 10px;
  box-sizing: border-box;
  }
}
 /deep/ .ant-form-item-label{
  width: 100px !important;
 }
.remark{
    >div{
    display: flex;
    >div {
    width: 64%;
    >span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    }
     span{
      font-size: 14px;
     }
    }
}
 /deep/ .ant-form-item-control-wrapper{
    width: 130px;
  }
.goods-input{
 /deep/ .ant-form-item-children{
    display: flex !important;
    .ant-input-number,
    .ant-input{
      border-radius: 5px 0 0 5px ;
    }
    .ant-btn{
      border-radius:0 5px 5px 0;
      border-left: none;
      width: 40% !important;
    }
  }
  .ant-input-affix-wrapper{
    width: 60%;
  }
}
.Beyond-the-hidden{
  display: flex;
  >div,
    b{
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    }
}
</style>
<template>
  <a-form layout="inline" style="width: 100%" :form="form" class="form">
    <a-form-item class="goods-input" label="体积" >
      <a-input-number
        :min="0.00"
        :max="99999"
        :step="0.01"
        :precision="2"
        @blur="computeVomlue()"
        v-decorator="['volume', { initialValue: '0.00', rules: [{ required: true, message: '体积' }] }]"
      /><a-button disabled>M³</a-button>
    </a-form-item>
    <a-form-item label="长" class="goods-input">
      <a-input-number
        :min="0"
        :max="99999"
        :step="1"
        @blur="compute"
        :precision="0"
        placeholder="长cm"
        v-decorator="['length', { initialValue: '' }]"
      /> <a-button disabled>CM</a-button>
    </a-form-item>
    <a-form-item label="宽" class="goodsFromWidth goods-input">
      <a-input-number
        :min="0"
        :max="99999"
        :step="1"
        @blur="compute"
        :precision="0"
        placeholder="宽cm"
        v-decorator="['width', { initialValue: ''}]"
      /><a-button disabled>CM</a-button>
    </a-form-item>
    <a-form-item label="高" class="goods-input">
      <a-input-number
        :min="0"
        :max="99999"
        :step="1"
        @blur="compute"
        placeholder="高cm"
        :precision="0"
        v-decorator="['high', { initialValue: '' }]"
      /><a-button disabled>CM</a-button>
    </a-form-item>

    <a-form-item class="input-form-right" label="商品数量">
      <a-input-number
        style="width:100%"
        :min="1"
        :max="99999"
        :step="1"
        :precision="0"        
        v-decorator="['goodsNum', { initialValue: '', rules: [{ required: true, message: '商品数量' }] }]"
      />
    </a-form-item>
    
    <a-form-item label="重量" class="goods-input">
      <a-input-number 
        :min="0.1"
        :max="99999"
        :step="0.1"
        :precision="1" 
        v-decorator="['weight', { rules: [{ required: true, message: '重量' }] }]" /><a-button disabled>KG</a-button>  
    </a-form-item>
    
    <a-form-item label="托盘" class="goods-input" v-if="orderInfo.isPalletFee ==1">
      <a-input-number 
        :min="0"
        :max="99999"
        :step="1"
        :precision="0" 
        v-decorator="['palletNum', { rules: [{ required: true, message: '托盘' }] }]" /><a-button disabled>个</a-button>  
    </a-form-item>

    <a-form-item label="派货入仓" class="goods-input" v-if="orderInfo.orderType == 1 && orderInfo.dispatchType == 1">
      <a-radio-group v-decorator="['isDispatchWarehouseFee', {rules: [{ required: true }] }]">
        <a-radio :key="index" v-for="(item, index) in isPutWarehouseData" :value="item.enumValue">{{
          item.enumName
        }}</a-radio>
      </a-radio-group>
    </a-form-item>
  
    <a-form-item>
      <a-button
        type="primary"
        style="border-radius:5px;"
        v-if="orderInfo.orderType == 1 && (orderInfo.orderCurrentStep == 2 || orderInfo.orderCurrentStep == 1) && orderInfo.paymentState == 2"
        @click="saveGoods()"
        v-action:modifyGoods
      >
        预估价格</a-button
      >
    </a-form-item>
    <a-row :gutter="24" type="flex" style="margin-top: 20px;border-top: 1px solid #e8e8e8">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>实际重量:</span>  {{ orderInfo.actualWeight }} KG</a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>回单:</span>  {{ orderInfo.receiptTypeVal }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>物品类型:</span>  {{ goodsInfo.goodsTypeVal }} </a-col>

      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"> <span>物品名称:</span><b>{{ goodsInfo.goodsName }}</b>  </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" class="remark">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>温区:</span>  {{ goodsInfo.temperatureZoneVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>包装:</span>  {{ goodsInfo.packingDesc }} </a-col>   
    </a-row>
    <a-row :gutter="24" type="flex" class="remark">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden">
        <span> 是否进口:</span><div> {{ goodsInfo.isImportVal }} <a v-if="goodsInfo.isImport == 1" @click="certificate()"> 点击查看三证</a></div>
      </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"> <span style="font-size:16px">备注:</span>
        <div> <a-tooltip><template slot="title">{{ goodsInfo.goodsRemark }}</template> {{ goodsInfo.goodsRemark }}</a-tooltip></div>
      </a-col>
    </a-row>
    <a-row :gutter="24" type="flex" style="margin-top: 20px" class="row-footer-freight" v-if="orderInfo.orderShow">
     
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"> <span>付款方式:</span> {{ orderInfo.paymentWayVal }} </a-col>
      <a-col v-if="orderInfo.paymentCustomerCodeVal != ''" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>企业客户:</span>  {{ orderInfo.paymentCustomerCodeVal }}
      </a-col>
      <a-col v-if="orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>是否装卸费:</span>  {{ orderInfo.isHandlingFeeVal }}
      </a-col>

      <a-col v-if="(orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4) && orderInfo.isHandlingFee ==1" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>装卸费:</span>  {{ orderInfo.handlingFee }}
      </a-col>

      <a-col v-if="orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>是否托盘费:</span>  {{ orderInfo.isPalletFeeVal }}
      </a-col>

      <a-col v-if="(orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4) && orderInfo.isPalletFee ==1" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>托盘费:</span>  {{ orderInfo.palletFee }}
      </a-col>


      <a-col v-if="orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>是否收分拣费:</span>  {{ orderInfo.isSortingFeeVal }}
      </a-col>

      <a-col v-if="(orderInfo.paymentWay == 3 || orderInfo.paymentWay == 4) && orderInfo.isSortingFee ==1" :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
       <span>分拣费:</span>  {{ orderInfo.sortingFee }}
      </a-col>

      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"> <span>是否保价:</span>{{ orderInfo.isInsuredVal }} </a-col>

      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"> <span>声明价值:</span>{{ orderInfo.insuredPrice }} </a-col>
    </a-row>

    <a-row v-if="orderInfo.orderShow" :gutter="24" type="flex" style="margin-top: 20px;" class="row-footer-freight">     
      <a-col v-for="(item, index) in orderPriceList" :key="index" :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <span>{{ item.title }}:</span>  {{ item.priceVal }}
      </a-col>
    </a-row>

    <a-row v-if="orderInfo.orderShow" :gutter="24" type="flex" style="margin-top: 20px !important;text-align: right;width:100%;borderTop: 1px solid #e8e8e8;">
      <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24" style="width:90%"> <span style="color:red">总计:</span>{{ orderInfo.actualPrice }} </a-col>
    </a-row>

    <a-modal v-model="priceDetailVisible" title="价格明细" @ok="priceConfirm" class="price-detail">
      <p :key="index" v-for="(item, index) in priceList"><span>{{ item.title }}：</span>{{ item.priceVal }}</p>

      <a-row :gutter="24" type="flex" style="margin-top: 20px !important;text-align: right;width:100%;">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24" style="width:90%"> <span style="color:red">总计：</span>{{ calculateActualPrice }} </a-col>
      </a-row>
    </a-modal>

    <certificate ref="certificateModal" />
  </a-form>
</template>    
<script>
import pick from 'lodash.pick'
import Certificate from '../modules/Certificate'
import { saveGoods } from '@/api/order'
export default {
  name: 'GoodsForm',
  components: {
    Certificate,
  },
  props: {
   
  },
  data() {
    return {
      goodsInfo: [],
      orderInfo: [],
      certificateInfo: [],
      priceDetailVisible: false,
      priceList: [],
      orderPriceList: [],
      calculateActualPrice:0,
      isPutWarehouseData:[],
      form: this.$form.createForm(this),

    }
  },
 
  methods: {
    load(record) {
     
      this.orderInfo = record.orderInfo
      this.goodsInfo = record.goodsInfo
      this.certificateInfo = record.goodsInfo.certificateInfo
      this.orderPriceList = record.priceDetailList
     
      var formVal = {}
      formVal.volume = this.goodsInfo.volume      
      formVal.length = this.goodsInfo.volumeLwh.length
      formVal.width = this.goodsInfo.volumeLwh.width
      formVal.high = this.goodsInfo.volumeLwh.high
      formVal.weight = this.goodsInfo.weight      
      formVal.goodsNum = this.goodsInfo.goodsNum
      formVal.isDispatchWarehouseFee = this.orderInfo.isDispatchWarehouseFee              
      const {
        form: { setFieldsValue },
      } = this
      this.$nextTick(() => {
        setTimeout(() => {
            setFieldsValue(
              pick(formVal, [
                'volume',               
                'width',
                'length',
                'high',
                'weight',
                'actualWeight',
                'goodsNum',
                'isDispatchWarehouseFee',
              ])
            )
        })
        if(this.orderInfo.isPalletFee ==1){
            this.form.setFieldsValue({'palletNum':this.goodsInfo.palletNum})          
        }                        
      })
    },

    goodsLoad(result){
      console.log('result',result)
      this.isPutWarehouseData = result.isDispatchWarehouseFee
    },

    priceConfirm() {
      this.priceDetailVisible = false
    },
    saveGoods() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.$notification['error']({
            message: '获取表单错误',
            description: values,
          })
        }        
        var orderParam = values        
        orderParam.orderId = this.orderInfo.id
        orderParam.id = this.goodsInfo.id
        orderParam.requestType = 1
        return saveGoods(orderParam).then((res) => {
          if (res.code == 0) {
            this.priceDetailVisible = true
            this.calculateActualPrice = res.result.actualPrice
            this.priceList = res.result.priceDetailList
          } else {
            this.$notification['error']({
              message: '保存信息有误',
              description: res.message,
            })
          }

          return res.result
        })
      })
    },

    certificate() {
      if (this.goodsInfo.isImport == 1) {
        this.$refs.certificateModal.certificate(this.certificateInfo)
      }
    },

    computeVomlue() {
      var volume = this.form.getFieldValue('volume')
      var goodsNum = this.form.getFieldValue('goodsNum')
      var computeVolume = this.computeVolumeByLwh()
      if (computeVolume != volume) {
        this.form.setFieldsValue({ width: '', high: '', length: '' })
      }    
      this.computePalletNum(volume)         
    },
    computeVolumeByLwh() {
      var length = this.form.getFieldValue('length')
      var width = this.form.getFieldValue('width')
      var high = this.form.getFieldValue('high')
      var volume = this.form.getFieldValue('volume')     
      if (length != null && width != null && high != null) {
        var volume = (length * width * high) / 1000000
        if (volume <= 0.01) {
          volume = '0.00'
        }
        if (volume > 99999) {
          volume = '99999'
        }
        volume = this.fixedTwo(volume)        
      }
      return volume
    },
    compute() {
      var goodsNum = this.form.getFieldValue('goodsNum')
      var volume = this.computeVolumeByLwh()      
      if (volume != 0) {
        const {
          form: { setFieldsValue },
        } = this

        this.form.setFieldsValue({ volume: volume })
        this.computePalletNum(volume)
      }
    },
    fixedTwo(number){
        let tempVal = parseFloat(number).toFixed(2)        
        return tempVal
    },
    computePalletNum(volume){  
        if(this.orderInfo.isPalletFee == 1){            
            let palletNum = 0
            console.log('palletNumRule',this.orderInfo.palletNumRule)
            if(this.orderInfo.palletNumRule != 0){                                                   
              palletNum = Math.ceil(volume/this.orderInfo.palletNumRule)                                 
            }            
            this.form.setFieldsValue({'palletNum':palletNum})             
        }        
    }

  },
}
</script>
