 <style lang="less" scoped>
    .ant-modal-body{
      .ant-row-flex >div{
        min-height: 50px;
        line-height: 30px;
        display: flex;
        >span{
          display: inline;
          width:150px;
          font-size: 16px;
          color: #000;
        }
        >div{
          display: inline;
          line-height: 30px;
          width: 580px;
        
        }
      }
      .ant-row-flex:nth-of-type(n+2){
        border-top: 1px solid #e8e8e8;
        padding-top: 10px;
      }
    }
 </style>
  <template>
    <a-modal
        title="物流信息"
        :width="800"
        :visible="visible"       
        @cancel="handleCancel"
    >
     <div class="table-operator">
            
         <a-row v-if="logisticsList != null" v-for="(item,index) in logisticsList" :key="index" :gutter="24" type="flex" >                     
              <a-col :xl="5" :lg="12" :md="24" :sm="24" :xs="24">
                   {{ item.nodeTime }}
              </a-col>
               <a-col :xl="19" :lg="12" :md="24" :sm="24" :xs="24">                    
                    <div> 
                        <a-tooltip>
                            <template slot="title">
                            {{ item.nodeContent }}
                            </template>
                            {{ item.nodeContent }}
                        </a-tooltip>
                    </div>
              </a-col>            
         </a-row>  
         <a-row v-if="logisticsList == null">
            暂无
         </a-row>     
      </div>
     <template slot="footer">    
      <a-button key="forward"  type="primary" @click="confirm()">确定</a-button>
    </template>  
  </a-modal>
</template>  

<script>
import { logisticsList } from '@/api/order'
export default {
  name: 'logistics',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      logisticsList:{},           
    }
  },
  methods: {
    logistics (orderId) {
        var param = { orderId: orderId }
        console.log('param',param)
        return logisticsList(param).then((res) => {
          console.log('logisticsList',res)
          if (res.code == 0) {
            this.logisticsList = res.result !=null ? res.result.transportInfo : null;
            this.visible = true
          } else {
            this.$notification.error({
              message: '物流信息',
              description: res.message,
            })
          }
        })
     
    },
    confirm () {
        this.visible = false
    },
    handleCancel () {    
      this.visible = false     
    }
  }
  
}
</script>