<style lang="less" scoped>
    .ant-row-flex{
      display: flex;
      align-items: center;
      >div:nth-child(1){
        width: 100px;
      }
    }
    .table-operator{
      img{
        border-radius: 5px;
      }
    }
</style>
  <template>
    <a-modal
        title="订单证书"
        :width="500"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @cancel="handleCancel"
    >
     <div class="table-operator">         
          <a-row :gutter="24" type="flex" > 
              <a-col :xl="6" :lg="24" :md="24" :sm="24" :xs="24">  
                  关单:     
              </a-col>     
              <a-col :xl="18" :lg="24" :md="24" :sm="24" :xs="24">
                        <img @click="imgShow($event)" id="depositReceipt" src="" alt="关单" height="160" width="200">
              </a-col>            
         </a-row>

          <a-row :gutter="24" type="flex" style="margin-top:20px;">
              <a-col :xl="6" :lg="24" :md="24" :sm="24" :xs="24">
                检验检疫证:      
              </a-col>   
                   
              <a-col :xl="18" :lg="24" :md="24" :sm="24" :xs="24">
                        <img @click="imgShow($event)" id="pratique" src="" alt="检验检疫证" height="160" width="200">
              </a-col>            
         </a-row>

          <a-row :gutter="24" type="flex" style="margin-top:20px;"> 
           <a-col :xl="6" :lg="24" :md="24" :sm="24" :xs="24">  
                核酸证:      
              </a-col>  
                
              <a-col :xl="18" :lg="24" :md="24" :sm="24" :xs="24">
                        <img @click="imgShow($event)" id="nuclein" src="" alt="核酸证" height="160" width="200">
              </a-col>            
         </a-row>        
      </div>
     <template slot="footer">    
      <a-button key="forward"  type="primary" @click="confirm()">确定</a-button>
    </template>  

     <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelModel">
          <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

  </a-modal>
</template>  

<script>
import {ACCESS_TOKEN} from "@/store/mutation-types";
import Vue from "vue"; 
export default {
  name: 'Certificate',
  data () {
    return {
      certificateInfo:[],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,  
      previewImage:"",
      previewVisible:false,    

    }
  },
  methods: {
    certificate(certificateInfo) {
       this.visible = true
       console.log('certificateInfo',certificateInfo)
       this.certificateInfo = certificateInfo
       
       var depositReceiptUrl = "/service_provider/upload/getPic?fileName="+this.certificateInfo.depositReceipt       
       this.getimgblob(depositReceiptUrl,"depositReceipt")

       var pratiqueUrl = "/service_provider/upload/getPic?fileName="+ this.certificateInfo.pratique
       this.getimgblob(pratiqueUrl,"pratique") 
 
       var nucleinUrl = "/service_provider/upload/getPic?fileName="+this.certificateInfo.nuclein
       this.getimgblob(nucleinUrl,"nuclein")   
      
    },
    confirm () {
        this.visible = false
    },
    handleCancel () {    
      this.visible = false     
    },
    imgShow($event){
        console.log($event.path[0].currentSrc);
        this.previewImage = $event.path[0].currentSrc
        this.previewVisible = true
    },
    async  getimgblob(url,id){
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = e => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {          
          var imgUrl = URL.createObjectURL(request.response)         
          document.getElementById(id).src =  imgUrl
        }
      }
      request.send(null)
    },
    handleCancelModel(){
        this.previewVisible = false
    }
  }
  
}
</script>
<style lang="less">
  .ant-modal-body >img{
    padding: 15px !important;
  }
</style>