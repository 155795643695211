  <template>
    <a-modal
        title="提货单"
        :width="550"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @cancel="handleCancel"
    >
     <div class="table-operator">         
        <a-row :gutter="24" type="flex" >        
              <a-col :key="index" v-for="(imgUrl,index) in pickupBills"  :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
                        <img @click="imgShow($event)" :src="imgUrl" alt="head-photo" height="200" width="200">
              </a-col>            
         </a-row>        
      </div>
     <template slot="footer">    
      <a-button key="forward"  type="primary" @click="confirm()">确定</a-button>
    </template>  
    
     <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelModel">
          <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-modal>
</template>  

<script>
import {ACCESS_TOKEN} from "@/store/mutation-types";
import Vue from "vue"; 
export default {
  name: 'Reject',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      confirmLoading: false,  
      previewImage:"",   
      previewVisible:false, 
      pickupBills:[],
    }
  },
  methods: {
    bill(pickupBillsArray) {    
      this.visible = true      
      for(var i=0;i<pickupBillsArray.length;i++){
          var imgUrl = "/service_provider/upload/getPic?fileName="+pickupBillsArray[i]       
          this.getimgblob(imgUrl)
      }
    },
    imgShow($event){
        console.log($event);
        this.previewImage = $event.path[0].currentSrc
        this.previewVisible = true
    },
    async  getimgblob(url){
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = e => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {          
          var imgUrl = URL.createObjectURL(request.response)         
          this.pickupBills.push(imgUrl)          
        }
      }
      request.send(null)
    },
    confirm () {
        this.visible = false
    },
    handleCancel () {    
      this.visible = false     
      
    },
    handleCancelModel(){
         this.previewVisible = false
    }
  }
  
}
</script>
<style lang="less" scoped>
  .table-operator{
    >div{
      display: flex;
      flex-wrap: wrap;
      >div{
        margin: 0 0 20px 0 !important;
        display: flex;
        width: 50%;
        justify-content: center;
        img{
          border-radius: 5px;
        }
      }
    }
  }
</style>