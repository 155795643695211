<style lang="less" scoped>
.form {
  .ant-row-flex {
    min-height: 40px;
    line-height: 40px;
    >span {
      display: inline-block;
      width: 70px;
    }
    .image {
      display: flex;
      img {
        width: 120px;
        margin: 10px;
      }
    }
  }
}
.Beyond-the-hidden{
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
 > div,
  b{
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
}
</style>
<template>
  <div layout="inline" class="form">
    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="Beyond-the-hidden"><span> 订单号: </span>
       <div> <a-tooltip><template slot="title">{{ orderInfo.orderSn }}</template> {{ orderInfo.orderSn }}</a-tooltip></div>
      </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>订单阶段:</span> {{ orderInfo.orderCurrentStepVal }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" style="margin-top: 20px">      
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>下单来源:</span> {{ orderInfo.orderSourceVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>订单分类:</span> {{ orderInfo.pickupWayVal }}{{ orderInfo.dispatchWayVal }} </a-col>             
    </a-row>  

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>取货顺序: </span> {{ orderInfo.pickupSeqVal }} </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span>送货顺序:</span> {{ orderInfo.dispatchSeqVal }} </a-col>      
    </a-row>  

    <a-row :gutter="24" type="flex" style="margin-top: 20px">
        <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"
              ><span> 订单状态:</span> {{ orderInfo.orderStateVal }}
        </a-col>      
        <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"
          ><span> 提货状态:</span> {{ orderInfo.pickupStateVal }}
        </a-col>
    </a-row> 

     <a-row :gutter="24" type="flex">               
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"
            ><span> 运输状态:</span> {{ orderInfo.transportStateVal }}
          </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"
            ><span> 派送状态:</span> {{ orderInfo.dispatchStateVal }}
          </a-col>
     </a-row>

    <a-row :gutter="24" type="flex">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <span> 温区:</span> {{ goodsInfo.temperatureZoneVal }}
      </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"><span> 声明价值:</span> {{ orderInfo.insuredPrice }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <span>回单类型:</span> {{ orderInfo.receiptTypeVal }}
      </a-col>
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"> <span>计费方式:</span> {{ orderInfo.chargeTypeVal }} </a-col>
    </a-row>

    <a-row :gutter="24" type="flex">
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
        <span>是否进口:</span> {{ goodsInfo.isImportVal }}
        <a v-if="goodsInfo.isImport == 1" @click="certificate()"> 点击查看三证</a></a-col
      >
      <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24"
        ><span> 车辆类型:</span> {{ orderInfo.vehicleTypeVal }}
      </a-col>
    </a-row>

    <a-row :gutter="24" type="flex" v-if="goodsInfo.isImport == 1">
      <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24">
        <div class="image">
          <img src="" />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="24" type="flex">
      <a-col
        class="Beyond-the-hidden"
        v-if="
          (orderInfo.pickupWay == 1 && orderInfo.dispatchWay == 1) ||
          (orderInfo.pickupWay == 1 && orderInfo.dispatchWay == 2)
        "
        :xl="12"
        :lg="12"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <span>发货时间:</span><b> {{ orderInfo.expectPickupDate }}</b>
      </a-col>
      <a-col
        class="Beyond-the-hidden"
        v-if="
          (orderInfo.pickupWay == 1 && orderInfo.dispatchWay == 1) ||
          (orderInfo.pickupWay == 2 && orderInfo.dispatchWay == 1)
        "
        :xl="12"
        :lg="12"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <span> 到货时间:</span><b>{{ orderInfo.expectDispatchDate }}</b>
      </a-col>
    
   
    </a-row>
    
    <certificate ref="certificateModal" />
  </div>
</template>
<script>
import Certificate from '../modules/Certificate'
export default {
  name: 'WholeCarOrderForm',
  components: {
    Certificate,
  },
  props: {},
  data() {
    return {
      orderInfo: {},
      goodsInfo: {},
      certificateInfo: {},
    }
  },
  mounted() {},
  methods: {
    load(orderInfo, goodsInfo) {
      this.orderInfo = orderInfo
      this.goodsInfo = goodsInfo
      this.certificateInfo = goodsInfo.certificateInfo
    },
    certificate() {
      if (this.goodsInfo.isImport == 1) {
        this.$refs.certificateModal.certificate(this.certificateInfo)
      }
    },
  },
}
</script>
